import { graphql } from "@/gql"

export const ANONYMOUS_DELETE_PATIENT = graphql(`
mutation AnonymousDeletePatient($id: uuid!) {
  delete_account(where: {patient_id: {_eq: $id}}) {
    affected_rows
  }

  delete_patient_by_pk(id: $id) {
    id
  }

  updateUsers(where: {account: { patient_id: {_eq: $id}}}, _set: { disabled: true }) {
    affected_rows
  }
}
`)

export const QUERY_PATIENT_LIST = graphql(`
query QueryPatientList {
  patient {
    id
    identifier
  }
}
`)

export const ANONYMOUS_UPDATE_PATIENT = graphql(`
mutation AnonymousUpdatePatient(
  $id: uuid!
  $patientSetInput: patient_set_input!
) {
  update_patient_by_pk(pk_columns: {id: $id}, _set: $patientSetInput) {
    id
    identifier
    center_id
    machine_id
    birth_date
  }
}
`)

export const SUBSCRIBE_PATIENTS = graphql(`
subscription SubscribePatients(
  $where: patient_bool_exp = {},
  $order_by: [patient_order_by!]!
  $limit: Int!
  $offset: Int!
) {
  patient(where: $where, order_by: $order_by, offset: $offset, limit: $limit) {
    ...Patient
  }
}
`)

export const SUBSCRIBE_PATIENTS_COUNT = graphql(`
subscription SubscribePatientsCount($where: patient_bool_exp = {}) {
  patient_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
`)

export const QUERY_ALL_PATIENTS = graphql(`
query QueryAllPatients($where: patient_bool_exp = {}) {
  patient(where: $where) {
    ...Patient
  }
}
`)

export const QUERY_ALL_CENTER_PATIENTS = graphql(`
query QueryAllCenterPatients($centerId: uuid!) {
  patient(where: {center_id: {_eq: $centerId}}) {
    ...Patient
  }
}
`)

export const QUERY_ALL_OPERATOR_PATIENTS = graphql(`
query QueryAllOperatorPatients($operatorId: uuid!) {
  patient_operator(where: {operator_id: {_eq: $operatorId}}) {
    patient {
      ...Patient
    }
  }
}
`)

export const QUERY_PATIENT = graphql(`
query QueryPatient($id: uuid!) {
  patient_by_pk(id: $id) {
    ...Patient
  }
}
`)

export const QUERY_ALL_PATIENT_SESSIONS = graphql(`
query QueryAllPatientSessions(
  $id: uuid!
  $where: session_data_bool_exp!
  $withoutSessionData: Boolean!
) {
  patient_by_pk(id: $id) {
    sessions: session_data(
      where: $where
      order_by: [ {
         date_session: desc
      }]
    ) {
      ...Session
      json_data @skip(if: $withoutSessionData)
    }
  }
}
`)

export const CHECK_TRANSMISSION = graphql(`
query CheckTransmission($id: uuid!) {
  patient_by_pk(id: $id) {
    accept_data_transmission
  }
}
`)

export const QUERY_PATIENT_INFOS = graphql(`
query QueryPatientInfos(
  $id: uuid!
  $offset: Int!
  $limit: Int!
  $where: session_data_bool_exp!
  $order_by: [session_data_order_by!]!
  $withoutSessionData: Boolean!
) {
  patient_by_pk(id: $id) {
    ...Patient
    sessions: session_data(
      where: $where
      order_by: $order_by
      offset: $offset
      limit: $limit
    ) {
      ...Session
      json_data @skip(if: $withoutSessionData)
    }

    session_data_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
}
`)

export const ADD_PATIENT = graphql(`
mutation AddPatient(
  $id: uuid!
  $firstname: String!
  $lastname: String!
  $identifier: String!
  $machine_id: uuid!
  $centerId: uuid!
  $phone: String!
  $email: citext!
  $birthDate: timestamptz = ""
  $patientOperators: [patient_operator_insert_input!]!
) {
  insert_patient_one(object: {
    id: $id
    identifier: $identifier
    center_id: $centerId
    machine_id: $machine_id
    birth_date: $birthDate
    accounts: {
      data: {
        firstname: $firstname
        lastname: $lastname
        user: {
          data: {
            email: $email
            phoneNumber: $phone

            # Default values
            locale: "en"
            defaultRole: "patient"
          }
        }
      }
    }
  }) {
    ...Patient
  }

  insert_patient_operator(objects: $patientOperators) {
    affected_rows
    returning {
      id
      patient_id
      operator_id
    }
  }
}
`)

export const DELETE_PATIENT = graphql(`
mutation DeletePatient($id: uuid!) {
  delete_account(where: {patient_id: {_eq: $id}}) {
    affected_rows
  }

  delete_patient_by_pk(id: $id) {
    id
  }

  deleteUsers(where: {account: { patient_id: {_eq: $id}}}) {
    affected_rows
  }
}
`)

export const UPDATE_PATIENT_PROFILE = graphql(`
mutation UpdatePatientProfile($id: uuid!, $accept_data_transmission: Boolean = false, $birth_date: timestamptz = "") {
  update_patient_by_pk(_set: {accept_data_transmission: $accept_data_transmission, birth_date: $birth_date}, pk_columns: {id: $id}) {
    id
    accept_data_transmission
    birth_date
  }
}
`)

export const UPDATE_PATIENT = graphql(`
mutation UpdatePatient(
  $id: uuid!
  $firstname: String!
  $lastname: String!
  $phone: String!
  $email: citext!
  $patientSetInput: patient_set_input!
  $patientOperators: [patient_operator_insert_input!]!
) {
  updateUser(pk_columns: {id: $id}, _set: {
    phoneNumber: $phone
    email: $email
  }) {
    id
    phoneNumber
    email
  }

  update_account_by_pk(pk_columns: {id: $id}, _set: {
    firstname: $firstname
    lastname: $lastname
  }) {
    id
    firstname
    lastname
  }

  resetOps: delete_patient_operator(
    where: {
      patient_id: {_eq: $id}
    }
  ) {
    affected_rows
    returning {
      id
      patient_id
      operator_id
    }
  }

  addOps: insert_patient_operator(
    objects: $patientOperators
  ) {
    affected_rows
    returning {
      id
      patient_id
      operator_id
    }
  }

  update_patient_by_pk(pk_columns: {id: $id}, _set: $patientSetInput) {
    id
    identifier
    center_id
    machine_id
    birth_date
    patient_operator {
      id
      operator {
        id
        identifier
      }
    }
  }
}
`)
