import { Distributor_Bool_Exp, Distributor_Order_By, InputMaybe, Order_By } from "@/gql/graphql"
import { ref, computed } from "vue"

export function useDistributorsFilters() {
  const columnOrders = ref<Distributor_Order_By>({ id: Order_By.Desc })
  const textSearch = ref("")
  const statusesSelected = ref<string[]>([])
  const zonesSelected = ref<string[]>([])

  const statusFilters = computed<Distributor_Bool_Exp | null>(() => statusesSelected.value.length === 0 ? null : {
    primary_account: {
      user: {
        disabled: {
          _in: statusesSelected.value.map(e => e === "false")
        }
      }
    }
  })

  const countryFilters = computed<Distributor_Bool_Exp | null>(() => zonesSelected.value.length === 0 ? null : {
    _or: zonesSelected.value.map(z => ({
      geographic_zones: {
        _contains: `{ ${z} }` as unknown as InputMaybe<Array<string>>
      }
    }) as Distributor_Bool_Exp)
  })

  const textFilters = computed<Distributor_Bool_Exp | null>(() => textSearch.value === "" ? null :
    {
      _or: [
        { address: { _ilike: `%${textSearch.value}%` } },
        {
          identifier: {
            _ilike: `%${textSearch.value}%`
          }
        },
        {
          company_name: {
            _ilike: `%${textSearch.value}%`
          }
        },
        {
          accounts: {
            firstname: {
              _ilike: `%${textSearch.value}%`
            },
          }
        },
        {
          accounts: {
            lastname: {
              _ilike: `%${textSearch.value}%`
            }
          }
        },
        {
          accounts: {
            user: {
              displayName: {
                _ilike: `%${textSearch.value}%`
              }
            }
          }
        },
        {
          accounts: {
            user: {
              email: {
                _ilike: `%${textSearch.value}%`
              }
            }
          }
        },
        {
          accounts: {
            user: {
              phoneNumber: {
                _ilike: `%${textSearch.value}%`
              }
            }
          }
        },
        {
          comments: {
            _ilike: `%${textSearch.value}%`
          }
        }
      ]
    }
  )

  const filters = computed<Distributor_Bool_Exp>(() => {
    return {
      _and: [
        textFilters.value,
        statusFilters.value,
        countryFilters.value,
      ].filter((f): f is NonNullable<Distributor_Bool_Exp> => f !== null)
    }
  })

  return {
    textSearch,
    statusesSelected,
    zonesSelected,
    columnOrders,
    filters,
  }
}
