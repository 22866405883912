<template>
  <v-menu :close-on-content-click="false" :offset="24">
    <template v-slot:activator="{ props }">
      <div v-bind="props" :class="class">
        <v-btn-top-bar
          v-bind="props"
          :append-icon="icon"
          rounded="0"
          :class="modelValue.length === 0 ? 'rounded-lg' : 'rounded-s-lg'"
        >
          {{ title }}
        </v-btn-top-bar>
        <v-btn-top-bar
          v-if="modelValue.length > 0"
          icon="mdi-close-circle-outline"
          density="comfortable"
          rounded="0"
          class="rounded-e-lg"
          @click="clear"
        >
        </v-btn-top-bar>
      </div>
    </template>
    <div class="d-flex flex-column pa-2 ma-0 rounded-lg bg-background-700 elevation-2" :style="{ 'min-width': '300px' }">
      <v-text-field
        v-model="search"
        :placeholder="title"
        clearable
        dense
        hide-details
        class="mb-2"
      />
      <v-virtual-scroll
        :height="300"
        :items="filteredFilters"
      >
        <template v-slot:default="{ item }">
          <v-checkbox
            hide-details
            :key="item.value"
            :label="item.title"
            :model-value="modelValue.includes(item.value)"
            @update:model-value="toggleCheckbox(item.value)"
          />
        </template>
      </v-virtual-scroll>
    </div>
  </v-menu>
</template>
<script setup lang="ts">
import { computed, ref, toRefs } from 'vue';

interface FilterValue {
  title: string;
  value: string;
}

const props = defineProps<{
  filters: FilterValue[];
  modelValue: string[];
  class?: string;
  icon: string;
  title: string
  multiple?: boolean;
}>();

const { modelValue } = toRefs(props);
const search = ref('');

const emit = defineEmits<{
  "update:model-value": [string[]]
}>();

const filteredFilters = computed(() => {
  return props.filters.filter((filter) => filter.title.toLowerCase().includes(search.value?.toLowerCase() ?? ''))
  .sort((a, b) => a.title.localeCompare(b.title));
});

function toggleCheckbox(filter: string) {
  if (!props.multiple) {
    emit('update:model-value', [filter]);
  } else {
    const newValues = modelValue.value.includes(filter)
      ? modelValue.value.filter((value) => value !== filter)
      : [...modelValue.value, filter];
    emit('update:model-value', newValues);
  }
}

function clear() {
  emit('update:model-value', []);
}
</script>
