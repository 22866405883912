import { Center_Bool_Exp, Center_Order_By, Order_By } from "@/gql/graphql";
import { ref, computed } from "vue";

export function useCentersFilters() {
  const columnOrders = ref<Center_Order_By>({ id: Order_By.Desc })
  const textSearch = ref("");
  const zonesSelected = ref<string[]>([])

  const countryFilters = computed<Center_Bool_Exp | null>(() => zonesSelected.value.length === 0 ? null : {
    country: {
      _in: zonesSelected.value
    }
  });

  const textFilters = computed<Center_Bool_Exp>(() =>
    textSearch.value === "" ? {} :
      {
        _or: [
          { identifier: { _ilike: `%${textSearch.value}%` } },
          { name: { _ilike: `%${textSearch.value}%` } },
          { address: { _ilike: `%${textSearch.value}%` } },
          {
            primary_account: {
              firstname: { _ilike: `%${textSearch.value}%` },
            }
          },
          {
            primary_account: {
              lastname: { _ilike: `%${textSearch.value}%` },
            }
          },
          {
            primary_account: {
              user: { email: { _ilike: `%${textSearch.value}%` } },
            }
          },
          {
            primary_account: {
              user: { phoneNumber: { _ilike: `%${textSearch.value}%` } },
            }
          },
          { comments: { _ilike: `%${textSearch.value}%` } },
        ],
      }
  );

  const filters = computed<Center_Bool_Exp>(() => {
    return {
      _and: [
        textFilters.value,
        countryFilters.value,
      ].filter((f): f is NonNullable<Center_Bool_Exp> => f !== null)
    }
  })

  return {
    textSearch,
    zonesSelected,
    columnOrders,
    filters
  }
}
