import {defineStore} from "pinia";
import {ref} from "vue";
import {provideApolloClient, useMutation} from '@vue/apollo-composable';

import {useI18n} from "@/plugins/i18n";
import {apolloClient} from "@/plugins/apollo";
import {authClient} from "@/plugins/nhost";

import {ASK_FOR_DELETION, LOGIN, RESEND_INVITE, SEND_PINCODE} from "./queries";
import {Role, roles} from "./models";
import {handleErrors} from "@/utils/errors";
import {useUserStore} from "@/store/user";
import router from "@/router";
import { useActionReporting } from "@/services/action_reporting";
import { ToastType, useToastStore } from "../toast";

provideApolloClient(apolloClient);

export const useAuthStore = defineStore("auth", () => {
  const userStore = useUserStore()

  const isSignedIn = ref<boolean>(false);
  const userId = ref<string | null>(null);
  const userRole = ref<Role>('anonymous' as Role);
  const accessToken = ref<string | null>(null);

  authClient.onAuthStateChanged((_) => {
    isSignedIn.value = authClient.isAuthenticated()

    userId.value = authClient.getUser()?.id || null
    userRole.value = (authClient.getUser()?.defaultRole || 'anonymous') as Role

    if (isSignedIn.value && userId.value) {
      const u = authClient.getUser()
      if (!u) return
      userStore.setUser(u.id, u.defaultRole)
    }
  })

  authClient.onTokenChanged((session) => {
    accessToken.value = session?.accessToken || null
  })

  function ensureAuthLoaded() {
    return new Promise<void>((resolve) => {
      authClient.isAuthenticatedAsync().then(() => {
        resolve()
      })
    });
  }

  function isA(roles: Role[]): boolean {
    return roles.some(r => r === userRole.value)
  }

  function getDashboard(): string {
    return roles[userRole.value].dashboard
  }

  async function signIn(email: string, password: string): Promise<void> {
    const {t} = useI18n()

    const {onDone, onError, mutate} = useMutation(LOGIN);

    return new Promise((resolve, reject) => {
      onDone((result) => {
        if (result.data?.login?.error) {
          reject(t(result.data?.login?.error))
        } else {
          resolve();
        }
      })

      onError((e) => reject(handleErrors(e)))

      mutate({
        email,
        password,
      })
    })
  }

  async function askForDeletion(): Promise<void> {
    const {t} = useI18n()
    const {mutate, onDone, onError} = useMutation(ASK_FOR_DELETION)

    return new Promise((resolve, reject) => {
      onDone((result) => {
        if (result.errors) {
          console.error(result.errors)
          reject(t('common.internal-error'))
        } else {
          logout();
          resolve();
        }
      })
      onError((e) => reject(handleErrors(e)))
      mutate({id: userId.value})
    })
  }

  async function changeEmail(newEmail: string): Promise<void> {
    const {t} = useI18n()
    const url = `${import.meta.env.VITE_APP_URL}/redirect/email-updated`
    const res = await authClient.changeEmail({newEmail, options: {redirectTo: url}})
    if (res.error) throw new Error(t(`profile.${res.error.error}`))
  }

  async function changePassword(newPassword: string): Promise<void> {
    const {t} = useI18n()
    const res = await authClient.changePassword({newPassword})
    if (res.error) throw new Error(t(`profile.${res.error.error}`))
  }

  async function resetPassword(email: string): Promise<void> {
    const {t} = useI18n()
    const url = `${import.meta.env.VITE_APP_URL}/change-password`
    const res = await authClient.resetPassword({email, options: {redirectTo: url}})
    if (res.error) throw new Error(t(`profile.${res.error.error}`))
  }

  async function validatePincode(pincode: string, email: string, password: string): Promise<void> {
    const {t} = useI18n()

    const {onDone, onError, mutate} = useMutation(SEND_PINCODE);

    return new Promise((resolve, reject) => {
      onDone(async (result) => {
        if (result.data?.sendPincode?.error) {
          reject(t(result.data?.sendPincode?.error))
        } else {
          const sessionRes = await authClient.refreshSession(result.data?.sendPincode?.refreshToken ?? undefined)
          if (sessionRes.error) {
            console.error(sessionRes.error)
            reject(sessionRes.error.message)
          } else {
            await router.push({name: 'Home'})
            resolve()
          }
        }
      })

      onError((e) => reject(handleErrors(e)))

      mutate({
        email,
        password,
        pincode,
      })
    })
  }

  async function resendInvite(id: string): Promise<void> {
    const { t } = useI18n()
    const { onError, onDone, mutate } = useMutation(RESEND_INVITE)
    const toast = useToastStore()

    return new Promise((resolve, reject) => {
      onDone((result) => {
        if (result.errors) {
          console.error(result.errors)
          reject(t('common.internal-error'))
        } else {
          toast.addToast(t('common.invite-sent'), ToastType.SUCCESS)
          resolve()
        }
      })

      onError((e) => reject(handleErrors(e)))

      mutate({userId: id})
    })
  }

  async function logout() {
    const { logLogout } = useActionReporting()
    await logLogout()
    await authClient.signOut()
    userId.value = null
    userRole.value = 'anonymous' as Role
    accessToken.value = null
    userStore.clearUser()

    // Reload the page and force the ws connection to close and reconnect
    location.reload()
  }

  return {
    isSignedIn,
    userId,
    signIn,
    validatePincode,
    logout,
    isA,
    changePassword,
    accessToken,
    ensureAuthLoaded,
    askForDeletion,
    resetPassword,
    changeEmail,
    getDashboard,
    resendInvite,
    userRole
  }
})
