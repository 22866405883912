<template>
  <v-dialog v-model="dialog" :width="370">
    <template v-slot:activator>
      <v-tooltip :text="$t('common.export.tooltip')" location="top">
        <template v-slot:activator="{ props }">
          <v-btn-top-bar @click="dialog = true" v-bind="props" append-icon="lucide:upload" :class="class">
            {{ $t('common.export.validate') }}
          </v-btn-top-bar>
        </template>
      </v-tooltip>
    </template>

    <v-card class="bg-background-500" rounded="lg">
      <v-card-text class="pa-1 ma-0">
        <v-container class="d-flex flex-column align-start">
          <h2 class="py-4">{{ $t('common.export.title') }}</h2>
          <div class="pb-4 text-typography-700">
            {{ $t('common.export.text') }}
          </div>
          <v-radio-group v-model="type">
            <v-radio label=".pdf" value="pdf" />
            <v-radio label=".csv" value="csv" />
          </v-radio-group>
          <v-btn :text="$t('common.export.validate')" class="text-none pt-4 pb-8 flex-grow-1 bg-primary-900 w-100"
            variant="flat" @click="exportData" />
          <div class="py-2"></div>
          <v-btn :text="$t('common.cancel')" class="text-none pt-4 pb-8 flex-grow-1 bg-background-300 w-100"
            variant="flat" @click="dialog = false" />
          <div class="pt-4 text-suppression-900">
            {{ $t('common.export.text-2') }}
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { useStorage } from '@vueuse/core';
import { ref } from 'vue';

type ExportType = 'csv' | 'pdf';

const emit = defineEmits<{
  click: [ExportType];
}>();

defineProps<{
  class?: string;
}>();

const storage = useStorage<ExportType>('export-btn', 'csv');

const type = ref<"csv" | "pdf">(storage.value);

const dialog = ref(false);

function exportData() {
  emit('click', type.value);
  dialog.value = false;
}
</script>
