<template>
  <app-top-bar>
    <app-date-picker class="mr-3" :title="$t('common.date-filter')" :model-value="dateSearched"
      @update:model-value="$emit('update:dateSearched', $event)" />
    <export-btn @click="exportData" />
    <simple-app-filter-drop-down
      class="ml-3"
      :title="$t('common.columns')"
      icon="lucide:between-vertical-start"
      :filters="columnsFilters"
      :model-value="columnsValues"
      @update:model-value="columnsValues = $event"
      multiple
    />
    <div class="flex-grow-1"></div>
    <v-search-filter :placeholder="$t('machine.actions.search')" :model-value="textSearched"
      @update:model-value="$emit('update:textSearched', $event)" />
  </app-top-bar>
  <app-table :headers="headers" :loading="loading" :limit="limit" :total="total" :current-page="page"
    @update:currentPage="$emit('update:page', $event)" :items="sessions" @update:filters="$emit('update:order', $event)"
    :defaultSelected="{ key: 'name', order: Order_By.Asc }">
    <tr v-for="session of sessions" :key="session.id">
      <td v-if="columnsValues.includes('start_date_session')">{{ d(session.date, 'short') }}</td>
      <td v-if="columnsValues.includes('end_date_session')">{{ formatHour(session.date) }}</td>

      <td v-if="firstColumn == 'patient' && columnsValues.includes('patient_id')">{{ session.patient_identifier }}</td>
      <td v-else-if="columnsValues.includes('machine_id')">{{ session.dyalizer_identifier }}</td>

      <td v-if="columnsValues.includes('session_number')">{{ session.identifier }}</td>
      <td v-if="columnsValues.includes('duration')">{{ session.duration }}</td>
      <td v-if="columnsValues.includes('blocking_alarms')">{{ session.blocking_alarms }}</td>
      <td v-if="columnsValues.includes('suspensive_alarms')">{{ session.suspensive_alarms_amount }}</td>

      <td v-if="columnsValues.includes('convective-volume-reinfusion')">{{ session.convective_volume_reinfusion }}</td>
      <td v-if="columnsValues.includes('volume-of-blood-treated')">{{ session.volume_of_blood_treated }}</td>
      <td v-if="columnsValues.includes('FCUP-duration')">{{ session.fcup_duration }}</td>
      <td v-if="columnsValues.includes('LA-realised-UF')">{{ session.la_realised_uf }}</td>

      <td class="d-flex justify-space-between align-session" v-if="columnsValues.length !== 0">
        <v-btn-icon icon="mdi-arrow-right" class="text-caption" :to="itemLink(session.id)" v-if="session.patient_identifier" />
      </td>
    </tr>
  </app-table>
</template>
<script setup lang="ts">
import { useI18n } from '@/plugins/i18n';
import { computed } from 'vue';
import { formatHour } from '@/utils/date';
import { Session } from '@/services/sessions/models';
import { Order_By } from '@/gql/graphql';
import { useActionReporting } from '@/services/action_reporting';
import { exportTo } from '@/utils/export';
import { AnyOrder } from '@/utils/order';
import SimpleAppFilterDropDown from '../atoms/SimpleAppFilterDropDown.vue';
import { useStorage } from '@vueuse/core'

const { logExport } = useActionReporting()
const { t, d } = useI18n()
const columnsValues = useStorage<string[]>('columnsValues', []);

const props = defineProps<{
  firstColumn: 'machine' | 'patient';
  dateSearched: string[];
  textSearched: string;
  page: number;
  sessions: Session[];
  loading: boolean;
  order: AnyOrder;
  limit: number;
  total: number | undefined;
  itemLink: (sessionId: string) => string;
  getAllSessions: () => Promise<Session[]>;
}>();

defineEmits<{
  'update:order': [AnyOrder];
  'update:textSearched': [string];
  'update:dateSearched': [string[]];
  'update:page': [number];
}>();

const allColumns = [
  {
    title: t("sessions.headers.start_date"),
    key: "start_date_session",
    filter: "date_session",
  },
  {
    title: t("sessions.headers.start_hour"),
    key: "end_date_session",
    filter: "date_session",
  },
  ...(props.firstColumn === 'patient' ? [
    {
      title: t("sessions.headers.patient_id"),
      key: "patient_id",
      filter: "patient.identifier",
    },
  ] : [
    {
      title: t("sessions.headers.machine_id"),
      key: "machine_id",
      filter: "machine.identifier",
    },
  ]),
  {
    title: t("sessions.headers.session_number"),
    key: "session_number",
    filter: "identifier",
  },
  {
    title: t("sessions.headers.duration"),
    key: "duration",
  },
  {
    title: t("sessions.headers.blocking_alarms"),
    key: "blocking_alarms",
  },
  {
    title: t("sessions.headers.suspensive_alarms"),
    key: "suspensive_alarms",
  },
  {
    title: t("session.session-report.label.convective-volume-reinfusion"),
    key: "convective-volume-reinfusion",
  },
  {
    title: t("session.session-report.label.volume-of-blood-treated"),
    key: "volume-of-blood-treated",
  },
  {
    title: t("unit.FCUP-duration.title"),
    key: "FCUP-duration",
  },
  {
    title: t("unit.LA-realised-UF.title"),
    key: "LA-realised-UF",
  },
  {
    title: '',
    key: "actions",
  }
]

const headers = computed(() => allColumns.filter(e => columnsValues.value.includes(e.key)));

const columnsFilters = computed(() => allColumns
  .filter(e => e.title !== '')
  .map(e => ({ title: e.title, value: e.key })));

async function exportData(type: 'csv' | "pdf") {
  const sessions = await props.getAllSessions();

  const data = sessions.map(e => [
    d(e.date, 'short'),
    formatHour(e.date),
    props.firstColumn === 'machine' ? e.dyalizer_identifier : e.patient_identifier,
    e.identifier,
    e.duration,
    e.blocking_alarms,
    e.suspensive_alarms_amount,
  ])

  await logExport("session")
  const prefix = props.firstColumn === 'patient' ? 'machine' : 'patient'
  exportTo(`${prefix}_sessions`, type, data, headers.value)
}
</script>
